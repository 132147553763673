import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

const classes = {
    Primary: 'button-Primary',
    Secondary: 'button-Secondary',
    Pastel: 'button-Pastel',
    Ghost: 'button-Ghost',
    BlueGhost: 'button-BlueGhost',
    Disable: 'button-Disable',
    Link: 'button-Link',
    Tertiary: 'button-Tertiary',
    extraSmall: 'buttonSize-extraSmall',
    extraSmall_v2: 'buttonSize-extraSmall_v2',
    extraSmall_v3: 'buttonSize-extraSmall_v3',
    extraSmall_v4: 'buttonSize-extraSmall_v4',
    small: 'buttonSize-small',
    medium: 'buttonSize-medium',
    large: 'buttonSize-large',
    blueSecondary: 'button-Blue-Secondary',
    yellowSecondary: 'button-yellow-Secondary',
    DisableV2: 'button-DisableV2',
}

const iconSize = {
    extraSmall: 'w-4',
    extraSmall_v2: 'w-5',
    small: 'w-6',
    medium: 'w-7',
    large: 'w-8',
}

const FontSize = {
    label: 'text-6xl',
    captionSmall: 'text-2xl',
    caption: 'text-xs',
    bodySmall: 'text-sm',
    overline: 'text-xl',
    body: 'text-base',
    bodyLarge: 'text-lg.5',
    subtitle: 'text-2xl',
}

const FontWeight = {
    400: 'font-normal',
    500: 'font-medium',
    600: 'font-semibold',
    700: 'font-bold',
}

/**
 * Primary UI component for user interaction
 * 
 * ## Button Size Chart
 * 
 * | size              | font-size | line-height | height |
 * | ----------------- | --------- | ----------- | ------ |
 * | **extraSmall**    | 14        | 20          | 28     |
 * | **extraSmall_v2** | 14        | 20          | 32     |
 * | **extraSmall_v3** | 14        | 20          | 36     |
 * | **extraSmall_v4** | 12        | 20          | 24     |
 * | **small**         | 14        | 20          | 40     |
 * | **medium**        | 16        | 24          | 48     |
 * | **medium_v2**     | 20        | 24          | 48     |
 * | **large**         | 16        | 20          | 64     |
 * 
 * ## If you need to put extra font-size
 * | Name             | font-size | line-height |
 * | ---------------- | --------- | ----------- |
 * | **label**        | 10        | 12          |
 * | **captionSmall** | 12        | 12          |
 * | **caption**      | 12        | 20          |
 * | **bodySmall**    | 14        | 20          |
 * | **overline**     | 16        | 20          |
 * | **body**         | 16        | 24          |
 * | **bodyLarge**    | 18        | 20          |
 * | **subtitle**     | 20        | 24          |
 */
const Button = ({ className, variant, children, size, disabled = false, onClick, type, iconType, iconDirection, fontSize, customIconSize, fontWeight, customIconColor, dataAutomation, ...props }) => {
    return (
        <button className={`${iconDirection === 'right' ? 'flex-row-reverse' : ''} ${className} button ${classes[variant] || classes.Primary} ${classes[size] || classes.medium} ${FontSize[fontSize] || ''} ${FontWeight[fontWeight] || ''}`} type={type} disabled={disabled} onClick={onClick} {...(dataAutomation && { 'data-automation': dataAutomation + '-button' })} {...props}>

            {iconType && <SvgIcon type={iconType} className={`${customIconSize ? customIconSize : iconSize[size] || iconSize.medium}`}
                color={customIconColor ? customIconColor : disabled ? 'text-primary1-300' : variant === 'Primary' ? 'text-basic-white' : 'text-primary1-500'}
            />}
            {children}
        </button>
    )
}

export default Button

Button.defaultProps = {
    className: '',
    variant: 'Primary',
    size: 'medium',
    disabled: false,
    iconType: '',
    iconDirection: 'left',
    fontSize: '',
}

Button.propTypes = {
    className: PropTypes.string,
    /**
     * Which variant should the button be?
     */
    variant: PropTypes.oneOf(['Primary', 'Secondary', 'Ghost', 'Disable', 'Link', 'Tertiary']),
    /**
     * Button content
     */
    children: PropTypes.node.isRequired,
    /**
     * How large should the button be?
     */
    size: PropTypes.oneOf(['extraSmall', 'extraSmall_v2', 'extraSmall_v3', 'extraSmall_v4', 'small', 'medium', 'medium_v2', 'large']),
    /**
     * Enable or Disabled
     */
    disabled: PropTypes.bool,
    iconType: PropTypes.oneOf(['IconPlusThick', 'IconPhoneFill', 'IconNotification', 'IconArrowRight', 'IconDownload', '']),
    iconDirection: PropTypes.oneOf(['left', 'right',]),
    fontWeight: PropTypes.number,
    type: PropTypes.string.isRequired,
}